/* src/MatchDay.css */

.matchday-page {
    padding: 20px;
  }
  
  .matchday-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #ecf0f1;
  }
  
  .sport-selection {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .sport-button {
    background-color: #3b1761;
    color: #ecf0f1;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .sport-button.active,
  .sport-button:hover {
    background-color: #5e2e8f;
  }
  
  .lineup-creator {
    margin-top: 20px;
  }
  