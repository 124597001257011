.player-data-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-color: #333; /* Subtle background */
}

/* Loading and Error Containers */
.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-left: -155px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #36a2eb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container p {
  color: red;
  font-size: 18px;
}

/* Year Filter */
.year-filter {
  margin-bottom: 20px;
}

.year-filter label {
  margin-right: 10px;
  font-weight: bold;
  color: #333;
}

.year-filter select {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Mini leaderboards (top and second row) */
.mini-leaderboards-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 20px;
}

/* Single mini leaderboard container */
.mini-leaderboard {
  flex: 1 1 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* Title with a nice blue color and slightly larger font */
.mini-leaderboard-title {
  margin: 0 0 10px 0;
  text-align: center;
  font-size: 1.05rem;
  font-weight: bold;
  color: #1e90ff; /* DodgerBlue color */
}

/* Scrollable table area */
.mini-leaderboard-table-wrapper {
  flex: 1;
  overflow-y: auto;
  max-height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fafafa;
}

/* Table styles for mini leaderboards */
.mini-leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.mini-leaderboard-table th,
.mini-leaderboard-table td {
  padding: 6px 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  color: #000; /* Explicitly set text color to black */
}

.mini-leaderboard-table thead th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;
}

.mini-leaderboard-table th,
.mini-leaderboard-table td {
  padding: 6px 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

/* We might not be using the toggle, but we keep styles for reference */
.mini-leaderboard-toggle {
  margin-top: 5px;
  background-color: #36a2eb;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px 10px;
}
.mini-leaderboard-toggle:hover {
  background-color: #2396da;
}

/* Main leaderboard container */
.leaderboard-container {
  width: 100%;
  padding: 20px 0;
}

/* Search bar */
.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Scrollable main leaderboard */
.table-wrapper {
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 500px;
  background-color: #fff;
}

/* Main Leaderboard Table */
.leaderboard {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard th,
.leaderboard td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.leaderboard th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

/* Striped rows */
.player-row.even {
  background-color: #f9f9f9;
}

.player-row.odd {
  background-color: #ffffff;
}

/* Nested table for position performance */
.nested-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.nested-table th,
.nested-table td {
  padding: 6px 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.nested-table th {
  background-color: #eaeaea;
}

/* Hide charts container if not in use */
.charts-container {
  display: none;
}

/* Recalculate button styling */
.recalculate-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.recalculate-button:hover {
  background-color: #45a049;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .mini-leaderboard-title {
    font-size: 0.85rem;
  }
  .mini-leaderboard-table th,
  .mini-leaderboard-table td {
    font-size: 0.75rem;
  }
  .leaderboard th,
  .leaderboard td {
    padding: 8px 10px;
    font-size: 14px;
  }
  .search-input {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .mini-leaderboards-row {
    flex-direction: column;
  }
  .leaderboard th,
  .leaderboard td {
    padding: 6px 8px;
    font-size: 12px;
  }
  .search-input {
    font-size: 12px;
  }
  .recalculate-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

/* Custom scrollbars for main table */
.table-wrapper::-webkit-scrollbar {
  width: 8px;
}
.table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}
.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom scrollbars for mini leaderboards */
.mini-leaderboard-table-wrapper::-webkit-scrollbar {
  width: 6px;
}
.mini-leaderboard-table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.mini-leaderboard-table-wrapper::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 3px;
}
.mini-leaderboard-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #777;
}
