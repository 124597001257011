/* src/Training.css */

.training-page {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .navbar {
    display: flex;
    background-color: #3b1761;
    padding: 0;
    margin: 0;
  }
  
  .nav-button {
    flex: 1;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    color: #ecf0f1;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .nav-button:hover {
    background-color: #5e2e8f;
    color: #fff;
  }
  
  .nav-button.active {
    background-color: #5e2e8f;
    color: #fff;
  }
  
  .training-content { /* Changed from .content to .training-content */
    flex: 1;
    padding: 20px;
    background-color: #333;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  