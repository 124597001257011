/* src/components/ErrorBoundary.css */

.error-boundary {
    padding: 50px;
    text-align: center;
    color: #ff0000;
  }
  
  .error-boundary button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .error-boundary button:hover {
    background-color: #0056b3;
  }
  