.new-game-setup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .new-game-setup-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    /* Adjust max-width and max-height for better fit */
    max-width: 90%;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
  
  /* Teams side by side */
  .teams-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  /* For smaller screens, stack teams vertically */
  @media (max-width: 768px) {
    .teams-wrapper {
      flex-direction: column;
    }
  }
  
  /* General styling */
  .modal-header {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .modal-title {
    color: #333;
    font-size: 1.5rem;
  }
  
  .modal-section {
    margin-bottom: 20px;
    flex: 1 1 45%; /* allows for side-by-side fitting */
  }
  
  .modal-section h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .modal-section input[type="text"],
  .modal-section input[type="date"],
  .modal-section input[type="color"],
  .modal-section select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .player-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .player-input input[type="text"] {
    flex: 3;
    margin-right: 10px;
  }
  
  .player-input button {
    flex: 1;
    padding: 10px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .player-input button:hover {
    background-color: #c82333;
  }
  
  .add-player-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .add-player-button:hover {
    background-color: #218838;
  }
  
  /* Buttons at bottom */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    padding: 10px 20px;
    background-color: #6c757d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  
  /* Additional responsive considerations for smaller screens */
  @media (max-width: 600px) {
    .modal-title {
      font-size: 1.2rem;
    }
  
    .submit-button, .cancel-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .modal-section input[type="text"],
    .modal-section input[type="date"],
    .modal-section input[type="color"],
    .modal-section select {
      padding: 8px;
    }
  }
  