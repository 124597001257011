/* SavedGames.css */

/* General styling for the saved games container */
.saved-games-container {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.saved-games-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.saved-games-container p {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}
/* Styling for each dataset section */
.dataset-section {
    margin-bottom: 30px;
    padding: 15px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dataset-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.dataset-header h3 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    margin: 0;
    color: #555;
}

.dataset-actions {
    display: flex;
    gap: 10px;
}

.download-dataset-button, .publish-dataset-button, .delete-dataset-button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.download-dataset-button {
    background: #28a745; /* Green */
}

.download-dataset-button:hover {
    background: #218838;
}

.publish-dataset-button {
    background: #17a2b8; /* Teal */
}

.publish-dataset-button:hover {
    background: #138496;
}

.delete-dataset-button {
    background: #dc3545; /* Red */
}

.delete-dataset-button:hover {
    background: #c82333;
}

/* Styling for the list of saved games within each dataset */
.saved-games-list {
    list-style: none;
    padding: 0;
}

.saved-game-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.game-info {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.game-name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.game-date {
    font-size: 14px;
    color: #2e2e2e;
    text-align: right;
    margin-top: -15px;
    padding-right: 15px;
}

.game-actions {
    display: flex;
    gap: 10px;
}

.load-button, .delete-button, .update-dataset-button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.refresh-button {
    padding: 8px 12px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-left: 760px;
    margin-bottom: 15px;
  }
  
  .refresh-button:hover {
    background-color: #0056b3;
  }
  

.load-button {
    background: #007bff;
}

.load-button:hover {
    background: #0056b3;
}

.delete-button {
    background: #dc3545;
}

.delete-button:hover {
    background: #c82333;
}

.update-dataset-button {
    background: #fd7e14; /* Orange */
}

.update-dataset-button:hover {
    background: #e8590c;
}

/* Styling for error messages */
.error {
    color: #dc3545;
    background-color: #f8d7da;
    padding: 10px 15px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 600px) {
    .dataset-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .game-date {
        text-align: left;
        margin-top: 5px;
        padding-right: 0;
    }

    .game-actions {
        margin-top: 10px;
    }
}