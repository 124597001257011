/* src/Upgrade.css */
.upgrade-container {
    text-align: center;
    margin: 50px auto;
    padding: 20px;
    max-width: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .upgrade-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .upgrade-container p {
    font-size: 16px;
    color: #666;
  }
   
  .upgrade-benefits {
    margin: 20px 0;
  }
  
  .upgrade-benefits h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
  }
  
  .upgrade-benefits ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }
  
  .upgrade-benefits ul li {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
  }
  
  .input-field {
    width: 95%;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .card-element {
    width: 96%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .upgrade-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upgrade-button:hover {
    background-color: #0056b3;
  }
  