/* Sidebar Styles */
.sidebar {
  width: 230px;
  background-color: #5e2e8f;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  transition: width 0.3s ease;
}

.sidebar.collapsed {
  width: 60px;
  align-items: center;
}

.sidebar .user-info {
  margin-bottom: 15px;
  text-align: center;
}

.logo {
  width: 90%;
  margin-bottom: 15px;
}

.sidebar.collapsed .logo {
  display: none; /* Hide the logo when sidebar is collapsed */
}

nav ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

nav ul li {
  margin: 8px 0;
  width: 100%;
}

nav ul li.separator {
  margin: 15px 0 8px;
  padding-left: 15px;
  font-size: 0.85rem;
  text-transform: uppercase;
  color: #ccc;
}

nav ul li button,
nav ul li label {
  width: 100%;
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 0.95rem;
  text-align: left;
  display: flex;
  align-items: center;
}

nav ul li button .icon,
nav ul li label .icon {
  margin-right: 8px;
  font-size: 1.1rem;
}

.sidebar.collapsed nav ul li button .icon,
.sidebar.collapsed nav ul li label .icon {
  margin-right: 0;
}

nav ul li button:hover,
nav ul li label:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.toggle-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.4rem;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 15px;
}

.toggle-button:focus {
  outline: none;
}

/* Sport Selection Styles */
.sport-select-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left edge */
}

.select-sport-text {
  font-size: 0.95rem;
  margin-bottom: 5px;
}

.sport-select-label select {
  width: 100%;
  background-color: #501387;
  border: none;
  color: white;
  padding: 4px;
  border-radius: 5px;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.sidebar.collapsed .sport-select-label select,
.sidebar.collapsed .select-sport-text {
  display: none;
}

.profile-button {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  color: white;
  padding: 8px 12px;
  font-size: 0.95rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.profile-button.loading {
  pointer-events: none;
  color: #fff;
}

.profile-button.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Existing media query */
@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.collapsed {
    width: 60px;
  }

  .toggle-button {
    display: block;
  }
}

/* New media query for portrait mode on small screens */
@media only screen and (max-width: 768px) and (orientation: portrait) {
  .sidebar {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .sidebar.collapsed {
    width: 100%;
    height: 60px;
  }

  .toggle-button {
    display: none; /* Hide the toggle button */
  }

  .sidebar .user-info {
    display: none; /* Hide the logo */
  }

  nav {
    width: 100%;
  }

  nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  nav ul li {
    margin: 0;
    width: auto;
  }

  nav ul li.separator {
    display: none;
  }

  nav ul li button,
  nav ul li label {
    padding: 0;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: white;
    width: auto;
  }

  nav ul li button .icon,
  nav ul li label .icon {
    margin: 0;
    font-size: 1.2rem;
  }

  .sport-select-label {
    display: none; /* Hide sport selection */
  }

  .profile-button {
    padding: 0;
  }
}
