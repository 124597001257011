/* src/components/Analysis.css */

.analysis-page {
  padding: 20px;
  background-color: #333;
  min-height: 100vh;
  color: #fff; /* Ensure text is readable on dark background */
}

.heatmap-container, .barchart-container {
  margin-bottom: 40px;
}

.export-button {
  margin-top: 20px;
}

.filters-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.filter-select {
  width: 200px;
}

@media (max-width: 850px) {
  .filters-container {
    flex-direction: column;
    align-items: center;
  }

  .filter-select {
    width: 100%;
  }
}
