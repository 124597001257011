.initial-setup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .initial-setup-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Existing styles from previous code */
  .modal-header {
    margin-bottom: 20px;
  }
  .modal-title {
    color: #333;
  }
  .modal-description {
    color: #555;
    margin-bottom: 30px;
  }
  .modal-buttons {
    display: flex;
    justify-content: space-around;
  }
  .modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
  }
  .modal-button-new-game {
    background-color: #007bff;
    color: #fff;
  }
  .modal-button-skip-setup {
    background-color: #6c757d;
    color: #fff;
  }
  .modal-button-new-game:hover {
    background-color: #0056b3;
  }
  .modal-button-skip-setup:hover {
    background-color: #5a6268;
  }
  