/* src/SportSelectionPage.css */

.sport-selection-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  overflow: hidden;
}

/* Semi-transparent overlay to ensure readability */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
  z-index: 1;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed; /* Fixed to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(80, 19, 135, 0.9); /* Semi-transparent background matching #501387 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5; /* Higher than other elements */
  animation: fadeIn 0.5s ease-out;
}

/* Fade-Out Animation */
.loading-overlay.fade-out {
  animation: fadeOut 0.5s forwards;
}

/* Loading Spinner */
.loading-spinner {
  width: 80px;
  height: 80px;
  border: 8px solid #d3d3d3; /* Light grey */
  border-top: 8px solid #ffffff; /* White */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Loading Logo */
.loading-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out;
}

/* Loading Text */
.loading-text {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
  animation: pulse 1.5s infinite;
}

/* Hero content (logo, tagline, subtext) */
.hero-content {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding: 20px;
  z-index: 2;
  animation: fadeIn 1s ease-out;
}

.hero-logo {
  width: 575px;
  height: auto;
  margin-bottom: 20px;
  animation: fadeIn 2s ease-out;
}

.hero-subtext {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-shadow:
    -0.5px -0.5px 0 #000,
    0.5px -0.5px 0 #000,
    -0.5px 0.5px 0 #000,
    0.5px 0.5px 0 #000;
  animation: fadeInUp 2s ease-out;
}

/* Sports selection 2x2 grid */
.sports-grid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 300px;
  z-index: 2;
  /* Removed animation to prevent movement on load */
}

.sports-grid button {
  padding: 15px 20px;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.85);
  color: #000;
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: 
    transform 0.3s ease, 
    background 0.3s ease, 
    color 0.3s ease, 
    border-color 0.3s ease;
}

.sports-grid button:hover {
  background: #fff;
  transform: translateY(-5px);
  color: #501387; /* Brand color on hover */
  border-color: #501387; /* Add border color change */
}

/* Footer */
.footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  color: #ccc;
  z-index: 2;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .hero-logo {
    width: 250px; /* Adjusted size for smaller screens */
  }

  .hero-tagline {
    font-size: 2rem;
  }

  .hero-subtext {
    font-size: 1rem;
  }

  .sports-grid {
    width: 80%;
    grid-gap: 15px;
  }

  .sports-grid button {
    font-size: 1.1rem;
  }

  .loading-spinner {
    width: 60px;
    height: 60px;
    border-width: 6px;
  }

  .loading-text {
    font-size: 1.2rem;
  }

  .loading-logo {
    width: 80px;
  }
}
