/* src/GaaLineup.css */

.gaa-lineup-container {
    max-width: 700px;
    margin: 0 auto;
    background: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    overflow: visible;
    position: relative;
  }
  
  /* Header Section */
  .gaa-lineup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .team-name-input {
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid #ccc;
    text-align: center;
    width: 180px;
    padding: 3px 0;
    margin-right: 10px;
  }
  
  .team-name-input:focus {
    outline: none;
  }
  
  .crest-upload {
    position: relative;
  }
  
  .team-crest {
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
  }
  
  /* Main Content Layout */
  .gaa-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Pitch styling */
  .gaa-pitch {
    flex: 2;
    position: relative;
    width: 100%;
    min-height: 555px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f8f8f8;
    background-image: url('./images/gaa-pitch.png');
    background-size: cover;
    background-position: center;
    overflow: visible;
  }
  
  .position-row {
    display: flex;
    justify-content: center;
    gap: 10px;
    white-space: nowrap;
  }
  
  .player-slot,
  .player-locked-slot {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    height: 85px;
    width: 100px;
    overflow: visible;
    box-sizing: border-box;
    vertical-align: top;
  }
  
  .sub-slot {
    height: 50px; 
    position: static;
  }
  
  /* Action buttons */
  .player-action-buttons {
    position: absolute;
    top: 53%;
    right: 0px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .remove-button,
  .lock-button {
    font-size: 10px;
    padding: 0 2px;
    border: none;
    cursor: pointer;
    background: #ddd;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
  }
  
  .remove-button:hover {
    background: #e74c3c;
    color: #fff;
  }
  
  .lock-button:hover {
    background: #2ecc71;
    color: #fff;
  }
  
  /* Drag handle */
  .drag-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    font-size: 14px;
    cursor: move;
    user-select: none;
  }
  
  /* Inputs */
  .player-number,
  .player-name,
  .player-notes {
    width: 50px;
    font-size: 10px;
    text-align: center;
    margin-bottom: 2px;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
  }
  
  .player-locked-slot .locked-number,
  .player-locked-slot .locked-name,
  .player-locked-slot .locked-notes {
    font-size: 10px;
    margin-bottom: 2px;
    word-wrap: break-word;
    text-align: center;
  }
  
  .manager-and-subs {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background: #f8f8f8;
    font-size: 10px;
    box-sizing: border-box;
    min-width: 100px;
  }
  
  .manager-section {
    margin-bottom: 5px;
  }
  
  .manager-section label {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 3px;
  }
  
  .manager-name-input {
    width: 100%;
    font-size: 10px;
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  /* Subs */
  .subs-section h3 {
    margin-bottom: 5px;
    font-size: 12px;
    border-bottom: 1px solid #333;
    display: inline-block;
  }
  
  .subs-grid {
    display: flex;
    flex-direction: column;
    gap: 0px; 
  }
  
  .substitute {
    display: inline-block;
    position: relative;
    height: 52px;
    width: 100px;
    vertical-align: top;
  }
  
  /* bottom controls */
  .bottom-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
  }
  
  .download-button-container {
    text-align: center;
  }
  
  /* Download (Green) Button */
  .download-button {
    background: #28a745;
    color: #fff;
    padding: 3px 10px;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .download-button:hover {
    background: #218838;
  }
  
  /* Reset (Red) Button */
  .reset-button {
    background: #dc3545;
    color: #fff;
    padding: 3px 10px;
    font-size: 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .reset-button:hover {
    background: #c82333;
  }
  
  .color-picker {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 10px;
  }
  
  /* Loading Overlay */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #28a745;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  /* Make subs name and notes text boxes wider */
  .sub-slot .player-name,
  .sub-slot .player-notes,
  .sub-slot .player-number {
    width: 100px; /* 100px more than the 50px for pitch players */
  }

  .sub-slot .player-action-buttons {
    right: -30px;
    top: 40%;  /* moves X and ✓ slightly to the right for subs */
    left: auto;
  }
  
  