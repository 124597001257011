/* Main wrapper to hold pitch, instructions, and setup */
.main-scroll-container {
  display: flex;
  flex-direction: row; /* Arrange children horizontally */
  width: 100%; /* Full width */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping */
}

/* Pitch and instructions container should scroll together */
.content {
  display: flex;
  flex-direction: row-reverse; /* Arrange children horizontally, reverse order */
  align-items: flex-start;
  width: 100%;
  overflow: auto;
  gap: 20px; /* Adjusted spacing between items */
}

/* Pitch container */
.pitch-graphic-container {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1; /* Allow to grow and fill available space */
  overflow: hidden; /* Hide overflow */
  touch-action: pinch-zoom; /* Allow pinch-to-zoom */
  flex-shrink: 0;
}

/* Scroll container for horizontal scrolling */
.scroll-container {
  display: flex; /* Arrange children horizontally */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping */
  width: 100%; /* Full width */
}

/* Team buttons container to align them in a row */
.team-buttons-container {
  display: flex;
  flex-direction: row; /* Align buttons horizontally */
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Space between buttons */
  flex-wrap: wrap; /* Allow wrapping */
}

/* Setup team container */
.setup-team-container {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  color: white;
  max-width: 95%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.setup-team-container h3 {
  text-align: center;
  margin-bottom: 10px;
}

/* Team players buttons */
.team-players {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.team-players button {
  flex-grow: 1;
  padding: 10px;
  text-align: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.team-players button:hover {
  background-color: #0056b3;
}

/* General button styles */
.button {
  background-color: #501387;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 5px;
  flex: 1;
  text-align: center;
}

.button:hover {
  background-color: #5e2e8f;
}

/* Pitch graphic */
.pitch-graphic {
  width: 100%;
  height: auto;
  max-width: 1000px;
}

/* Pitch and data container */
.pitch-and-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Allow to grow and fill available space */
  flex-shrink: 0;
}

/* Aggregated data container */
.aggregated-data-container {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px; /* Adjusted to prevent overlapping */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  max-width: 1000px;
}

/* Button container */
.button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.button {
  background-color: #501387;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 5px 0;
  width: 100%;
}

.button:hover {
  background-color: #5e2e8f;
}

/* Action buttons */
.action-buttons {
  display: flex;
  flex-direction: column;
}

.action-button {
  background-color: #501387;
  color: rgb(184, 184, 184);
  border: 1px solid;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 5px 0;
  width: 100%;
}

.action-button.pass {
  border-color: blue;
}

.action-button.assist {
  border-color: green;
}

/* Instructions container */
.instructions-container {
  max-width: 280px;
  min-width: 280px; /* Set a fixed width */
  width: 280px;
  padding: 20px;
  background-color: #2e2e2e;
  border-radius: 10px;
  color: white;
  word-wrap: break-word; /* Wrap long words */
  overflow: hidden; /* Hide overflow */
  box-sizing: border-box; /* Include padding in width */
  flex-shrink: 0; /* Prevent shrinking */
}

.instructions-container h3,
.instructions-container p {
  margin-bottom: 10px;
}

.instructions-container p {
  color: white;
  font-size: 14px;
  line-height: 1.4; /* Improve readability */
  white-space: normal; /* Ensure text wraps properly */
}

/* Dialog overlay to center the dialog */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Dialog container (Action Modal Styling) */
.dialog-container {
  background-color: #3e3e3e;
  border: 1px solid #000;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
  margin: 0 auto;
  position: relative;
}

/* Dialog header */
.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e2e2e;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dialog-header h3 {
  margin: 0;
  font-size: 12px;
  color: #fff;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: #fff;
}

.close-button:hover {
  color: red;
}

/* Form content */
.form-content {
  padding: 10px;
}

/* Form group inside modal */
.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: white;
  font-size: 12px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 6px;
  border: 1px solid #000;
  border-radius: 5px;
  background-color: #2e2e2e;
  color: white;
  box-sizing: border-box;
  height: 30px;
  font-size: 12px;
}

/* Button container inside dialog */
.dialog-container .button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 95%;
  background-color: #2e2e2e;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dialog-container .button {
  background-color: #501387;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.9rem;
  margin: 0 5px;
  border-radius: 5px;
  text-align: center;
}

.dialog-container .button:hover {
  background-color: #5e2e8f;
}

/* Custom slider container */
.custom-slider-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.custom-slider-container label {
  margin-right: 10px;
  color: white;
}

/* === New Review Data Modal Styles === */

.review-modal-container {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-modal-header {
  font-size: 1.2rem;
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
}

.review-table {
  width: 100%;
  border-collapse: collapse;
  color: #fff;
}

.review-table th,
.review-table td {
  border: 1px solid #555;
  padding: 8px;
  text-align: left;
}

.review-modal-btn {
  background-color: #501387;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.review-modal-btn:hover {
  background-color: #5e2e8f;
}

/* === Media Queries (existing ones follow) === */

/* Adjustments for screens up to 768px */
@media (max-width: 768px) {
  .content {
    gap: 20px;
  }

  .instructions-container {
    padding: 15px;
  }

  .button,
  .action-button {
    font-size: 0.8rem;
    padding: 8px;
    margin: 5px 0;
  }

  /* Dialog adjustments */
  .dialog-container {
    max-width: 90%;
    margin: 20px auto;
  }

  .dialog-container .button {
    font-size: 0.8rem;
    padding: 8px;
    margin: 0 5px;
  }

  .form-group input,
  .form-group select {
    font-size: 13px;
    padding: 5px;
    height: 28px;
  }

  .form-group label {
    font-size: 13px;
  }
}

/* Adjustments for screens up to 480px */
@media (max-width: 480px) {
  .content {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .instructions-container,
  .pitch-container,
  .pitch-and-data-container {
    width: 100%;
  }

  .button,
  .action-button {
    font-size: 0.75rem;
    padding: 8px;
  }

  /* Dialog adjustments */
  .dialog-overlay {
    align-items: center;
  }

  .dialog-container {
    max-width: 90%;
    padding: 15px;
    margin: 0 auto;
    transform: translateY(-200px); /* Move up by 200px in portrait mode */
  }

  .dialog-container .button {
    font-size: 0.75rem;
    padding: 8px;
    margin: 0 5px;
  }

  .form-group input,
  .form-group select {
    font-size: 12px;
    padding: 4px;
    height: 26px;
  }

  .form-group label {
    font-size: 12px;
  }
}

/* Adjustments for screens up to 375px */
@media (max-width: 375px) {
  .content {
    gap: 5px;
  }

  .button,
  .action-button {
    font-size: 0.7rem;
    padding: 6px;
  }

  /* Dialog adjustments */
  .dialog-container .button {
    font-size: 0.7rem;
    padding: 6px;
    margin: 0 3px;
  }

  .form-group input,
  .form-group select {
    font-size: 11px;
    padding: 3px;
    height: 24px;
  }

  .form-group label {
    font-size: 11px;
  }
}

/* Adjustments for screens up to 320px */
@media (max-width: 320px) {
  .content {
    gap: 2px;
  }

  .button,
  .action-button {
    font-size: 0.65rem;
    padding: 5px;
  }

  /* Dialog adjustments */
  .dialog-container .button {
    font-size: 0.65rem;
    padding: 5px;
    margin: 0 2px;
  }

  .form-group input,
  .form-group select {
    font-size: 10px;
    padding: 2px;
    height: 22px;
  }

  .form-group label {
    font-size: 10px;
  }
}

/* Landscape mode adjustments */
@media (orientation: landscape) {
  .dialog-overlay {
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
  }

  .dialog-container {
    transform: translate(-305px, -130px);
    max-height: calc(100vh - 40px);
    overflow-y: auto;
  }
}

/* Portrait mode adjustments */
@media (orientation: portrait) {
  .dialog-container {
    transform: translateY(-200px);
  }
}

/* Adjustments for landscape orientation on small screens */
@media (max-width: 667px) and (orientation: landscape) {
  .content {
    gap: 15px;
  }

  .pitch-container {
    width: 60%;
    padding: 10px;
  }

  .instructions-container {
    margin-top: 0;
    max-width: 40%;
  }

  .button-container,
  .action-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .button,
  .action-button {
    width: 48%;
    margin: 5px 1%;
  }
}

/* Adjustments for landscape orientation on very small screens */
@media (max-width: 568px) and (orientation: landscape) {
  .content {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .pitch-container {
    padding: 5px;
    width: 100%;
  }

  .instructions-container {
    margin-top: 10px;
    max-width: 100%;
  }

  .button-container,
  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .button,
  .action-button {
    width: 100%;
    margin: 5px 0;
  }
}
