/* src/Success.css */

/* Success container */
.success-container {
    background-color: #2e2e2e; /* Dark background */
    padding: 40px;
    border-radius: 10px;
    color: white;
    text-align: center;
    max-width: 600px;
    margin: 50px auto; /* Center the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    position: relative;
    animation: fadeIn 1s ease-in-out;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }
  
  /* Success heading */
  .success-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #4caf50; /* Green color to indicate success */
  }
  
  /* Success message */
  .success-container p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  /* Success icon */
  .success-container .success-icon {
    font-size: 4rem;
    color: #4caf50;
    margin-bottom: 20px;
    animation: bounce 2s infinite;
  }
  
  /* Bounce animation for the icon */
  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
  }
  
  /* Loading spinner */
  .success-container .spinner {
    border: 8px solid #f3f3f3; /* Light gray */
    border-top: 8px solid #4caf50; /* Green */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
  
  /* Spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Countdown timer */
  .success-container .countdown {
    font-size: 1rem;
    margin-top: 20px;
  }
  
  /* Redirect button */
  .success-container .redirect-button {
    background-color: #4caf50; /* Green */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin-top: 30px;
  }
  
  .success-container .redirect-button:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  /* Responsive adjustments */
  
  /* Tablets and small desktops */
  @media (max-width: 768px) {
    .success-container {
      padding: 30px;
    }
  
    .success-container h2 {
      font-size: 2rem;
    }
  
    .success-container p {
      font-size: 1rem;
    }
  
    .success-container .spinner {
      width: 50px;
      height: 50px;
      border-width: 6px;
    }
  
    .success-container .countdown {
      font-size: 0.9rem;
    }
  
    .success-container .redirect-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  
  /* Mobile devices */
  @media (max-width: 480px) {
    .success-container {
      padding: 20px;
    }
  
    .success-container h2 {
      font-size: 1.5rem;
    }
  
    .success-container p {
      font-size: 0.9rem;
    }
  
    .success-container .spinner {
      width: 40px;
      height: 40px;
      border-width: 4px;
    }
  
    .success-container .countdown {
      font-size: 0.8rem;
    }
  
    .success-container .redirect-button {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  