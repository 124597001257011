/* src/components/Players.css */

/* Overall Page Styling */
.players-page {
  padding: 40px 20px;
  background-color: #2c2c2c; /* Dark grey background */
  color: #ffffff; /* White text for general content */
  min-height: 100vh; /* Full viewport height */
  box-sizing: border-box;
}

/* Header Styles */
.players-page h2,
.players-page h3,
.players-page h4 {
  color: #ffffff; /* White color for all headings */
  margin-bottom: 20px;
}

.players-page h2 {
  font-size: 2.5em;
  font-weight: bold;
}

.players-page h3 {
  font-size: 1.8em;
  font-weight: semi-bold;
}

/* Search and Filter Section */
.search-filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.search-filter-section input,
.search-filter-section select {
  padding: 16px 15px;
  border: 1px solid #555555; /* Darker border for contrast */
  border-radius: 6px;
  background-color: #3a3a3a; /* Slightly lighter grey for input backgrounds */
  color: #ffffff; /* White text inside inputs */
  font-size: 1em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.search-filter-section input::placeholder,
.search-filter-section select::placeholder {
  color: #bbbbbb; /* Light grey for placeholders */
}

.search-filter-section input:focus,
.search-filter-section select:focus {
  border-color: #4caf50; /* Green border on focus */
  background-color: #4a4a4a; /* Lighten background on focus */
  outline: none;
}

/* Add Player Form */
.add-player-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #3a3a3a; /* Darker grey background for form */
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
}

.add-player-form input,
.add-player-form select {
  flex: 1 1 250px;
  padding: 12px 15px;
  border: 1px solid #555555; /* Darker border for inputs */
  border-radius: 6px;
  background-color: #4a4a4a; /* Slightly lighter grey for input backgrounds */
  color: #ffffff; /* White text inside inputs */
  font-size: 1em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.add-player-form input::placeholder,
.add-player-form select::placeholder {
  color: #bbbbbb; /* Light grey for placeholders */
}

.add-player-form input:focus,
.add-player-form select:focus {
  border-color: #4caf50; /* Green border on focus */
  background-color: #5a5a5a; /* Lighten background on focus */
  outline: none;
}

.add-player-form button {
  padding: 12px 25px;
  background-color: #4caf50; /* Green background for Add button */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-player-form button:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.add-player-form button:active {
  background-color: #3e8e41; /* Even darker green on click */
  transform: translateY(0); /* Reset position */
}

/* Players Table */
.players-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  background-color: #2c2c2c; /* Dark grey background for table */
  border-radius: 8px;
  overflow: hidden; /* Ensures rounded corners */
}

.players-table thead {
  background-color: #4caf50; /* Green background for table headers */
  color: #ffffff; /* White text for headers */
}

.players-table th,
.players-table td {
  border: 1px solid #555555; /* Darker border for table cells */
  padding: 12px 15px;
  text-align: center;
  font-size: 1em;
}

.players-table th {
  font-weight: bold;
}

.players-table tbody tr:nth-child(even) {
  background-color: #3a3a3a; /* Alternating row colors */
}

.players-table tbody tr:hover {
  background-color: #4a4a4a; /* Hover effect for rows */
}

.players-table td a {
  color: #4caf50; /* Green links to match theme */
  text-decoration: none;
  transition: color 0.3s ease;
}

.players-table td a:hover {
  color: #81c784; /* Lighter green on hover */
  text-decoration: underline;
}

/* Action Buttons within Table */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.action-buttons .edit-button,
.action-buttons .delete-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.9em;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-buttons .edit-button {
  background-color: #2196f3; /* Blue for Edit */
}

.action-buttons .edit-button:hover {
  background-color: #1976d2; /* Darker blue on hover */
  transform: scale(1.05);
}

.action-buttons .delete-button {
  background-color: #f44336; /* Red for Delete */
}

.action-buttons .delete-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-filter-section,
  .add-player-form {
    flex-direction: column;
  }

  .search-filter-section input,
  .search-filter-section select,
  .add-player-form input,
  .add-player-form select {
    flex: 1 1 100%;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-buttons .edit-button,
  .action-buttons .delete-button {
    width: 100%;
  }
}

/* Snackbar Styling Overrides (Optional) */
.MuiSnackbar-root {
  bottom: 20px;
}

.MuiAlert-root {
  border-radius: 4px;
}

/* Optional: Custom Scrollbar for Table (Webkit Browsers) */
.players-table {
  overflow-x: auto;
}

.players-table::-webkit-scrollbar {
  height: 8px;
}

.players-table::-webkit-scrollbar-track {
  background: #444444; /* Darker scrollbar track */
  border-radius: 4px;
}

.players-table::-webkit-scrollbar-thumb {
  background: #888888; /* Scrollbar thumb color */
  border-radius: 4px;
}

.players-table::-webkit-scrollbar-thumb:hover {
  background: #555555; /* Darker thumb on hover */
}
