/* src/components/TeamDetails.css */

/* 
  If you no longer need a "team-shot-data-container" class
  because you've replaced it with styled-components (e.g.,
  <PageContainer>), you can remove or adapt it.
  Otherwise, here is a darker, more consistent style:
*/
.team-shot-data-container {
    position: relative;
    background-color: #1c1c1c; /* matches the new PageContainer background */
    color: #f0f0f0;            /* lighter text for dark backgrounds */
    font-family: 'Inter', sans-serif; /* or Arial, sans-serif */
    min-height: 100vh;
    padding: 2rem;
  }
  
  /*
    The pitch container can still have a border and box shadow,
    but let's match it with #444 or #555 (consistent with the new theme).
    If you're now styling <Stage> directly in the component with inline
    styles or styled-components, you can remove or tweak these rules.
  */
  .stage-pitch-container {
    border: 2px solid #444;  /* updated from #fff to match the dark theme */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  /*
    Loading & Error containers: unify them with the new theme.
    (We changed background from #222 to #1c1c1c for consistency.)
  */
  .loading-container,
  .error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1c1c1c; /* unify with the rest of the app's background */
    color: #f0f0f0; /* ensure text is visible on dark BG */
    font-family: 'Inter', sans-serif;
  }
  
  /*
    Spinner color can match accent colors if you prefer.
    Currently uses #f3f3f3 for border and #36a2eb for the top.
    You could switch #36a2eb to something like #ffc107 to match your stats heading.
  */
  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #36a2eb; /* or #ffc107 if you want gold. */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* 
    Tooltip styling is mostly inline in the component, 
    but you could add more here if needed 
  */
  .tooltip {
    /* e.g., if you want to override or add transitions */
  }
  
  /* 
    Modal styling for the color-picker modal. 
    Much is handled in customModalStyles in JS, 
    but these classes can refine text or layout inside.
  */
  .color-modal-header {
    color: #f0f0f0;
    text-align: center;
  }
  
  .color-modal-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .color-modal-grid div {
    display: flex;
    flex-direction: column;
  }
  
  .color-modal-grid label {
    margin-bottom: 0.5rem;
    color: #f0f0f0; /* unify label color with the dark theme */
  }
  
  /*
    Example back-button styling (if still used).
    Otherwise, remove it if replaced by styled-components.
  */
  .back-button {
    padding: 8px 16px;
    background-color: #ff7043;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #f4511e;
  }
  
  /* Add or remove more styles as needed */
  