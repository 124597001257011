/* src/SportsDataHub.css */

.sports-datahub-container {
  padding: 40px;
  max-width: 1200px;
  margin: 50px auto;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.sports-datahub-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.sports-datahub-container p {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.search-filter-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.search-filter-container input[type="text"] {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-filter-container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.datasets-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.dataset-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}

.dataset-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.dataset-card h3 {
  margin: 15px 0 10px 0;
  color: #333;
  text-align: center;
}

.dataset-card p {
  font-size: 0.95rem;
  color: #555;
  margin-bottom: 5px;
}

.dataset-actions {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.dataset-actions .action-button {
  position: relative; /* For positioning the spinner */
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex: 1 1 calc(33.333% - 10px); /* Adjusted flex basis to fit three buttons */
  min-height: 40px; /* Ensures consistent button height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.dataset-actions .action-button:hover {
  opacity: 0.9;
}

.dataset-actions .action-button:nth-child(1) {
  background-color: #17a2b8; /* Teal */
  color: white;
}

.dataset-actions .action-button:nth-child(2) {
  background-color: #28a745; /* Green */
  color: white;
}

.dataset-actions .delete-button {
  background-color: #e74c3c; /* Red color for delete */
  color: white;
}

.dataset-actions .delete-button:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .search-filter-container {
      flex-direction: column;
      align-items: center;
  }

  .search-filter-container input[type="text"] {
      width: 80%;
  }

  .search-filter-container select {
      width: 80%;
  }

  .dataset-actions .action-button {
      flex: 1 1 100%; /* Make buttons full width on small screens */
  }
}

/* Spinner Styles */
.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  position: absolute;
  /* Center the spinner within the button */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

/* Hidden class to hide button text */
.button-text.hidden {
  visibility: hidden;
}

/* Adjust button styles when disabled */
.dataset-actions .action-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
