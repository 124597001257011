/* src/Profile.css */

/* Profile container */
.profile-container {
  background-color: #ffffff; /* White background */
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  margin: 50px auto; /* Center the container */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  color: #333; /* Dark text color */
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* User info section */
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.avatar-icon {
  font-size: 4rem;
  color: #4caf50; /* Green color to indicate active status */
  margin-right: 15px;
}

.user-info p {
  font-size: 1.1rem;
  color: #555; /* Slightly darker for better readability */
}

/* Profile headings */
.profile-container h2 {
  font-size: 2.0rem;
  margin-bottom: 20px;
  color: #333;
}

.profile-container h3 {
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #4caf50; /* Green to indicate subscription details */
}

/* Paragraphs */
.profile-container p {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.profile-container p strong {
  color: #555; /* Darker color for labels */
}

/* Error message styling */
.error-message {
  background-color: #ffdddd; /* Light red background */
  border-left: 6px solid #f44336; /* Red border on the left */
  margin-bottom: 20px;
  padding: 10px;
  text-align: left;
  border-radius: 5px;
  color: #a94442; /* Dark red text */
}

/* Buttons */
.profile-container button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Logout button */
.logout-button {
  background-color: #f44336; /* Red */
  color: white;
}

.logout-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
}

/* Cancel subscription button */
.cancel-button {
  background-color: #ff9800; /* Orange */
  color: white;
}

.cancel-button:hover {
  background-color: #e68900; /* Darker orange on hover */
}

/* Renew/Subscribe button */
.renew-button {
  background-color: #4caf50; /* Green */
  color: white;
}

.renew-button:hover {
  background-color: #43a047; /* Darker green on hover */
}

/* Withdraw section */
.withdraw-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.withdraw-section input[type="number"] {
  padding: 8px;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.withdraw-button {
  background-color: #17a2b8; /* Teal */
  color: white;
}

.withdraw-button:hover {
  background-color: #138496; /* Darker teal on hover */
}

/* Spinner styling (if used) */
.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #4caf50; /* Green */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */

/* Tablets and small desktops */
@media (max-width: 768px) {
  .profile-container {
    padding: 30px;
  }

  .profile-container h2 {
    font-size: 2rem;
  }

  .profile-container h3 {
    font-size: 1.5rem;
  }

  .user-info p {
    font-size: 1rem;
  }

  .avatar-icon {
    font-size: 3rem;
    margin-right: 10px;
  }

  .profile-container p {
    font-size: 1rem;
  }

  .profile-container button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .spinner {
    width: 50px;
    height: 50px;
    border-width: 6px;
  }
}

/* Mobile devices */
@media (max-width: 480px) {
  .profile-container {
    padding: 20px;
  }

  .profile-container h2 {
    font-size: 1.5rem;
  }

  .profile-container h3 {
    font-size: 1.2rem;
  }

  .user-info p {
    font-size: 0.9rem;
  }

  .avatar-icon {
    font-size: 2.5rem;
    margin-right: 8px;
  }

  .profile-container p {
    font-size: 0.9rem;
  }

  .profile-container button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border-width: 4px;
  }
}
