body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: #333; /* Dark background */
  color: white;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #333; /* Dark background for the app */
}

.main-container {
  display: flex;
  flex: 1;
  overflow: auto; /* Enable scrolling */
}

.content-area {
  flex: 1;
  padding: 1rem;
  overflow: auto;
}

.pitch-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333; /* Dark background behind the pitch */
  width: 100%;
}

@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    overflow: auto;
  }

  .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .sidebar.collapsed {
    width: 100%;
  }

  .content-area {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .button-container {
    flex-direction: column;
  }

  .button {
    width: 100%;
    margin: 5px 0;
  }

  .main-container {
    flex-direction: column;
  }

  .content-area {
    padding: 0.5rem;
  }

  .pitch-container {
    padding: 1rem;
  }
}

/* Additional media queries for iPhone screen sizes */
@media (max-width: 375px) { /* iPhone SE, iPhone 6/7/8 */
  .content-area {
    padding: 0.25rem;
  }

  .button {
    font-size: 0.875rem;
  }
}

@media (max-width: 320px) { /* iPhone 5/5S/5C */
  .content-area {
    padding: 0.25rem;
  }

  .button {
    font-size: 0.75rem;
  }
}